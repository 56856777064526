<template>
	<div class="laboratoryListBox">
		<van-sticky>
			<van-nav-bar title="实验室概览" placeholder  left-text="返回" left-arrow  @click-left="$router.push('/home')" />
			<van-dropdown-menu >
				<van-dropdown-item v-model="query.I_Level"    :options="InformationLevel"  @change='DropdownChagen' />
				<van-dropdown-item v-model="query.I_Category" :options="InformationCategory"  @change='DropdownChagen' />
			</van-dropdown-menu>
		</van-sticky>

		<div class="ListBox">
			<van-empty
			    v-show="this.list.length  < 1"
				class="custom-image"
				:image="require('./../../assets/Image/custom-empty-image.png')" 
			/>
			<van-list
				ref="check"
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
				>
				<div class="EventBox van-hairline--bottom" v-for="(item,index) in list" :key="index"  >
					<div class="ImgBox">
						<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators='false'>
							<van-swipe-item v-for="(ite, index) in item.I_ILChart" :key="index">
								<van-image :src="ite" />	
							</van-swipe-item>
						</van-swipe>
					</div>
					<div class="BoxEvent">
						<div class="TitleBox">
							<h4 class="one-txt-cut">{{item.I_Name}}</h4>
						</div>
						<div class="RowtIitle"> 
						    <p><strong><van-icon name="friends" /></strong>{{item.I_Charger}}</p>
						    <p><strong><van-icon name="phone-circle" /></strong>{{item.I_Phone}}</p>
						</div>
						<div class="RowtIitle"> 
						    <p><strong><van-icon name="setting" /></strong>{{item.I_Category}}</p>
						    <p><strong><van-icon name="medal" /></strong>{{item.I_Level}}</p>
						</div>
						<div class="RowtIitle"><strong><van-icon name="location" /></strong>{{item.I_Address}}</div>
					</div>
					<div class="BtnBox van-hairline--top">
						<van-button size='small' type="primary" @click="Details(item)">实验室详情</van-button>
						<van-button size='small' type="warning" @click="$router.push({path:'/makeAppointment/laboratoryInfo',query:{I_ID:item.I_ID,fromPath:encodeURI($route.path)}})">实验室预约</van-button>
					</div>
				</div>
			
			</van-list>
		</div>
	</div>
</template>
<script>
import { List,NavBar, Image as VanImage ,Icon,Button , Swipe, SwipeItem , DropdownMenu, DropdownItem ,Sticky,Empty } from 'vant';
import { Api_YyInformationController_GetPage } from "@/api/laboratory";
import {Api_GetCodeByIntTypes} from '@/api/utils'
import {DropdownMenuDate} from '@/utils/validate'
export default { 
	components:{
		[List.name]: List,
		[NavBar.name]: NavBar,
		[VanImage.name]: VanImage,
		[Icon.name]: Icon,
		[Button.name]: Button,
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
		[Sticky.name]: Sticky,
		[Empty.name]: Empty,
	},
    data() {
        return {
		
			InformationLevel: [],
			InformationCategory: [],
	  		query:{
				I_Category:'',
				I_Level:'',
				pageindex:0,
				pagesize:6,
			},
			list: [],
			
			loading: false,
			finished: false,
        }
	},
	created(){
		Api_GetCodeByIntTypes({
           type:'InformationLevel-InformationCategory' 
        }).then(res=>{
			res.InformationLevel.unshift({
				Code_Name:'实验室级别',
				Code_Value:''
			})
			
			res.InformationCategory.unshift({
				Code_Name:' 实验室类别',
				Code_Value:''
			})
			
			
			this.InformationLevel = DropdownMenuDate(res.InformationLevel)
			
			
            this.InformationCategory = DropdownMenuDate(res.InformationCategory)
        })
	},
	methods:{
		DropdownChagen(){
			this.list = [];
			this.query.pageindex = 0;
			this.loading =false;
			this.finished = false;
			this.$refs.check.check()
		},
		GetLit(){
			Api_YyInformationController_GetPage(this.query).then(res=>{
				this.loading = false
				this.list = this.list.concat(res.List)
				if( this.list.length>= res.AllConut ){
					this.finished = true
				}

			})
		},
		onLoad() {
			
			this.loading = true
			this.GetLit();
			this.query.pageindex++ 
		},
		Details(item) {
			this.$router.push({path:`/laboratoryDetails/${item.I_ID}`})
		}
	},
	
}
</script>
<style lang="scss" scoped>
.laboratoryListBox {
	.ListBox {
		margin-top: 10px;
		.EventBox {
			background: #fff;
			display: flex;
			flex-direction: column;
			.ImgBox {
				/deep/ .van-image__img {
					display: inline-block;
					height: 200px;
				}
			}
			.BoxEvent {
				flex: 1;
				padding:6px 0px;
				padding-left: 8px;
				padding-bottom: 0px;
				font-size: 13px;
				color: #333;
				.RowtIitle {
					line-height: 25px;
					display: flex;
					flex-direction: row;
					position: relative;
					color: #323233;
					strong {
						padding-right: 4px;
						color: #ff9254;
						font-weight: bold;
						display: flex;
						flex-direction: row;
						align-items: center;
						i {
							font-size: 17px;
						}
					}
					p:first-child {
						width: 48%;
					}
					p:last-child {
						flex: 1;
					}
					p {
						display: flex;
						flex-direction: row;
						align-items: center;
					}
					.StateBox {
						position: absolute;
						top: 0px;
						right: 0px;
						color:#07c160;
					}
					.acitve {
						color: #ee0a24;
					}
				}
				.TitleBox {
					h4 {
						font-size: 15px;
						line-height: 24px;
						padding-bottom: 5px;
					}
				}
			}
			.BtnBox {				
				display: flex;
				padding: 6px 8px;
				padding-top: 6px;
				padding-bottom: 16px;
				.van-button {
					margin-right: 15px;
					height: 28px;
				}
			}
		}
	}
	.custom-image {
		/deep/ .van-empty__image {
			width: 90px;
			height: 90px;
		}
	}
	.van-image {
		width: 100%;
	}
}
</style>